.contentCard {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  padding: 0 70px 0 30px;
  display: flex;
  align-items: center;
}
.boxInfoProyectos p{
  margin: 5px;
}
.boxGrafico {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  align-self: stretch;
}
.boxGrafico p{
  margin: 0px;
}
.title {
  /* padding: 15px 24px; */
  display: flex;
  align-items: center;
  align-self: stretch;
}
.h5 {
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
  color: rgba(33, 43, 54, 1);
}
.graficoTwo {
  flex: 1;
  align-self: stretch;
  position: relative;
}
.p-label {
  padding: 0 19px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.body1 {
  width: 142px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(99, 115, 129, 1);
  text-align: center;
}
.h3 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgba(33, 43, 54, 1);
  text-align: center;
}
.grafico {
  width: 240px;
  height: 240px;
  position: relative;
}
.frame589 {
  margin-right: 60px;
  position: relative;
}
.separador {
  width: 390px;
  height: 1px;
  background-color: rgba(145, 158, 171, 0.24);
  position: absolute;
  left: 0;
  top: 0;
}
.boxInfoProyectos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  /* height: 390px; */
}
.proyectoResidencial {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #dedede;
}
.indicador {
  width: 22px;
  height: 22px;
  background-color:rgba(220, 239, 149, 1);
  margin-right: 10px;
  border-radius: 50%;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}
.titleTwo {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.caption {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(101, 91, 154, 1);
}
.body2 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(38, 30, 96, 1);
  margin-right: 114px;
}
.body2Bold {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(38, 30, 96, 1);
  flex: 1;
  text-align: right;
}
.captionTwo {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(152, 145, 187, 1);
  margin-right: 122px;
}
.captionBold {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(152, 145, 187, 1);
  flex: 1;
  display: flex;
  text-align: right;
}
.indicadorTwo {
  width: 22px;
  height: 22px;
  background-color: rgba(164, 144, 238, 1);
  margin-right: 10px;
  border-radius: 50%;
}
.body2Two {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(38, 30, 96, 1);
  margin-right: 115px;
}
.indicadorThree {
  width: 22px;
  height: 22px;
  background-color: rgba(85, 123, 209, 1);
  margin-right: 10px;
  border-radius: 50%;
}
.indicadorFour {
  width: 22px;
  height: 22px;
  background-color: rgba(255, 186, 67, 1);
  margin-right: 10px;
  border-radius: 50%;
}
.body2Three {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(38, 30, 96, 1);
  margin-right: 122px;
}
.indicadorFive {
  width: 22px;
  height: 22px;
  background-color: rgba(235, 227, 215, 1);
  margin-right: 10px;
  border-radius: 50%;
}
.balance {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
}
.body2Four {
  width: 47px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(38, 30, 96, 1);
}
