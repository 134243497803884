.item-menu {
  background-color: rgba(235, 227, 215, 1);
  margin-bottom: 20px;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  /* width: 205px; */
  width: 100%;
  cursor: pointer;
}
.item-active-todo {
  background-color: var(--secondarycolorpalette-lightpurple-lighther);
  
}

.item-active-devise-lover {
  background-color: var( --primary-lighter);
 
}
.item-active-mis-finanzas {
  background-color: var(--secondarycolorpalette-lightorange-lighter)
  
}
.item-active-mi-sector {
  background-color: var(--secondarycolorpalette-lightblue-ligtheri)
  
}

