.cardsBlog {
  background-color:var(--secondarycolorpalette-hueso-lighter);
  border-radius: 8px;
  padding: 8px 8px 8px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
width: 100%;
}
.cardsBlog img.content{
  width: 121px;
  height: 143px;
}
.boxContent {
  display: flex;
  align-items: center;
}
.textContent {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.title {
font: var(--h6);
  color: rgba(49, 36, 120, 1);
  margin-bottom: 8px;
  text-align: left;
}
.categoryTag {
  margin-bottom: 8px;
  height: 32px;
}
.description {
  max-width: 207px;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color:var(--secondarycolorpalette-darkblue-main);
  text-align: justify;
  margin-left: 3px;
  margin-right: 3px;
}
.content {
  width: 30.97%;
  height: 86.11%;
}