.swhictPortafdolio {
    width: 100%;
    height: 100%;
    background-color: rgba(236, 236, 236, 1);
    border-radius: 20px;
    position: relative;
  }
  .btnSwitchInversiones {
    /* width: calc(50% - 73px);
    height: calc(100% - 20px); */
    background-color: var(--tertiary-main);
    border-radius: 20px;
    padding: 10px 36.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    left: 0;
    top: 0;
    transform: translate(12px, 0px);
    cursor: pointer;
  }

  .inversiones {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: var(--primary-main);
    text-align: center;
    margin: 0px;
  }
  .btnSwitchRendimientos {
   /*  width: calc(50% - 73px);
    height: calc(100% - 20px); */
    border-radius: 20px;
    padding: 10px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    right: 0;
    top: 0;
    transform: translate(-12px, 0px);
    cursor: pointer;
  }
  
  .rendimientos {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: rgba(49, 36, 120, 1);
    text-align: center;
    margin: 0px;
  }