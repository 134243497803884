.formLogin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sharp {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.signInToMinimal {
  align-self: stretch;
  text-align: center;
  margin-bottom: 8px;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: rgba(49, 36, 120, 1);
}
.signInToMinimalEmphasis1 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: rgba(33, 43, 54, 1);
}
.signInToMinimalEmphasis2 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  color: rgba(140, 170, 39, 1);
}
.enterYourDetailsB {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(91, 101, 118, 1);
  align-self: stretch;
  text-align: center;
}
.text-field-box{
  margin: 15px auto;
}
.four {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.form-box{
  width: 450px;
}
.logo-img{
  height: 100px;
  margin-bottom: 65px;
}
.form-title{
  text-align: center;
  display: block !important;
}
.form-title .form-title-sup{
  font-weight: 900;
  font-size: 32px;
  font-family: Poppins-Bold;
}
.form-title .form-title-sub{
  font-size: 16px;
}
.form-title .form-title-sub{
  font-size: 14px;
  font-family: Poppins-ExtraLight;
}
.form-title-sub{
  color: var(--text-onlight-secondary);
}
.form-footer{
  margin-top: 50px;
  justify-content: center !important;
}
.color-main {
  color: var(--primary-dark);
}
.color-tertiary {
  color: var(--tertiary-main);
}

.form-title .form-title-sub span.color-2{
  
}
.two {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.textField {
  height: 56px;
  width: 450px;
  /* &:not(:last-of-type) {
  margin-bottom: 24px;
  } */
}
.three {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.forgotPassword {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(28, 79, 193, 1);
  text-align: right;
}
.formLogin .button {
  width: 450px;
}
.buttonTwo {
  align-self: stretch;
}
.sponsor{
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}
.sponsor .sponsor__box{
  color: var(--tertiary-main);
  float: right;
  font-size: 13px;
  font-weight: 700;;
}

@media screen and (max-width: 500px) {
  .form-box{
    width: 340px;
  }
  .button {
    width: 100%;
  }
}