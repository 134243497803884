.welcome-container {
  display: flex;
  width: 100%;
  height: 535px;
  max-width: 1500px;
  margin: auto;
  padding-top: 110px;
  /* margin-bottom: 30px; */
}
.container-buttons-box {
  display: flex;
  gap: 22px;
}
.Welcome .btn-primary {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.Welcome .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.Welcome .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.Welcome .btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.Welcome .btn-primary.focus,
.btn-primary:checked {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.Welcome .dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}
.big-h1 {
  font-size: 45px;
  font-weight: bold;

  font-family: Poppins-bold;
}
.text-banner {
  width: 50%;
  height: 0;
  /* transform: translate(20px,-300px); */
  transform: translate(20px, -255px);
}
.text-small {
  text-align: left;
}
.text-header {
  text-align: left;
}
.secondary-lightblue {
  color: var(--secondarycolorpalette-lightblue-main);
}
.align-self-center {
  align-self: center;
  text-align: start;
}
.section {
  height: 500px;
  display: flex;
  padding-bottom: 20px;
}
.section-box {
  align-self: center;
  p {
    text-align: justify;
    color: var(--text-onlight-secondary);
    font-size: 14px;
  }
}
.section-box-70 {
  max-width: 70%;
  margin: auto;
}
.row-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  /* margin-right: -15px; */
  /*  margin-left: -15px; */
}
.img-border {
  margin-left: -30px;
  object-fit: contain;
  width: 100%;
  height: 100% !important;
  max-height: 500px;
}
.primary {
  color: var(--secondarycolorpalette-lightblue-darker);
}
.secondary {
  color: var(--primary-dark);
}
.section-purple {
  background: #261e60;
  background-image: url("../../assets/img_blue_background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.welcome-container-info-image {
  background-image: url("../../assets/img_background_picture.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.section-purple .secondary-lighter {
  color: var(--secundary-lighter);
}
.secondary-lighter {
  color: var(--secundary-lighter);
}
.section-purple .section-box {
  max-width: 80%;
  margin: auto;
}

.container-buttons-dropdown {
  margin-top: auto;
  display: none;
}
.container-buttons-dropdown.footer {
  margin-top: auto;
  display: none;
}

@media screen and (max-width: 830px) {
  .container-buttons-box {
    display: none;
  }
  .container-buttons-dropdown {
    display: flex;
  }
  .container-buttons-dropdown.footer {
    float: right;
    text-align: end;
    display: contents;
  }
}
.box-buttons-container {
  margin: auto;
}
.box-buttons {
  font-size: 14px;
  .link-about {
    cursor: pointer;
    color: #94b024;
  }
  .link {
    cursor: pointer;
    color: #252772;
  }
}
.Welcome .menu-text {
  margin-top: -30px;
  margin-bottom: -30px;
  svg {
    height: 90px;
  }
}
.column-rigth {
  width: 50%;
  height: 85% !important;
}
.Welcome .column-left {
  width: 100%;
}
.welcome-container .container {
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  height: 90%;
}
.Welcome .box {
  /* display: inline-block;
  width: 50%;
  height: 100%; */
  display: grid;
  width: 100%;
  height: 100%;
  margin: auto;
}
.welcome-img {
  margin: auto;
  margin: auto;
  display: inherit;
  width: 90%;
  min-height: calc(100%);
  height: 90% !important;
}
.Welcome .img-container {
  /* height: -webkit-fill-available; */
  height: 90% !important;
  border-radius: 10px;
  overflow: hidden;
}
.img-container img {
  object-fit: cover;
  width: 100%;
  height: 90% !important;
}
@media (min-width: 1200px) {
  .welcome-container .container {
    max-width: 1500px;
  }
}
@media (min-width: 576px) {
  .Welcome .container,
  .container-sm {
    max-width: 540px;
  }
}
@media screen and (max-width: 960px) {
  .Welcome .box {
    display: inline;
    width: 100%;
    height: 100%;
  }
  .Welcome .column-left {
    display: inline;
  }
}
@media screen and (max-width: 768px) {
  .Welcome .welcome-img {
    margin: auto;
    width: 100%;
    max-height: 70%;
    min-height: 70%;
  }
  .Welcome .welcome-container {
    max-height: 70%;
    min-height: 70%;
    height: 70%;
    padding-top: 110px;
    margin-bottom: 30px;
  }
  .img-border {
    max-height: 400px;
  }
  .text-header {
    font-size: 35px;
  }

  .text-banner {
    transform: translate(20px, -200px);
    width: 70%;
    .text-small {
      text-shadow: rgb(225, 232, 235) 1px 0px 0px,
        rgb(225, 232, 235) 0.540302px 0.841471px 0px,
        rgb(225, 232, 235) -0.416147px 0.909297px 0px,
        rgb(225, 232, 235) -0.989993px 0.14112px 0px,
        rgb(225, 232, 235) -0.653644px -0.756803px 0px,
        rgb(225, 232, 235) 0.283662px -0.958924px 0px,
        rgb(225, 232, 235) 0.96017px -0.279416px 0px;
    }
  }
}
@media screen and (max-width: 490px) {
  .text-banner {
    transform: translate(20px, -150px);
    width: 90%;
  }
  .text-header {
    font-size: 28px;
  }
  .text-small {
    font-size: 13px;
  }
}
.copy-rigth {
  margin-top: 20px;
}
.copy-rigth .float-content {
  font-size: 12px;
  color: var(--text-onlight-secondary);
  /*  transform: translate(0px, 40px);
    height: 40px; */
}
/* rewrite bootstrap*/
.translate-left {
  transform: translate(-154px, 0) !important;
}
@media (min-width: 1200px) {
  .welcome-container .welcome-container .container {
    max-width: 1500px;
  }
}
@media (min-width: 576px) {
  .welcome-container .container,
  .container-sm {
    max-width: 100%;
  }
}
.Welcome .carousel-container {
  height: auto;
}

@media (min-width: 992px) {
  .container {
    /* max-width: 960px; */
  }
}

@media (min-width: 768px) {
  .container {
    /* max-width: 720px; */
  }
  .Welcome .carousel-container {
    height: 350px;
  }
}
@media (max-width: 768px) {
  .Welcome .section {
    height: auto;
  }
  .translate-left {
    transform: translate(0, 0) !important;
    margin-left: 0;
    max-height: 300px;
  }
}

@media (min-width: 576px) {
  .container {
    /* max-width: 540px; */
  }
}
.react-multi-carousel-item {
  /* width: 438px !important; */
  /*  width:200% !important;; */
  /* aspect-ratio : 2 / 2; */
}
.cardsBlog {
  margin: auto;
}

.custom-footer {
  transform: translate(0px, 35px);
}
