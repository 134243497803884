.logo-s-info{
    min-width: 250px;
    margin-top: -10px;
}
.white{
    color: white !important;
}
@media only screen and (max-width: 960px) {
    .logo-s-info{
       margin-top: 20px;
    }
    .text-small.s-info{
        margin-left: 17px;
    }
}
@media only screen and (max-width: 1108px) {
   
    .text-small.s-info{
        margin-left: 17px;
    }
}