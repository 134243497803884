.categoryTag {
    background-color: rgba(255, 220, 161, 1);
    border-radius: 12px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vector {
    width: 16.52px;
    height: 19.38px;
    margin-right: 10px;
  }
  .categoryName {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(27, 25, 73, 1);
  }


  .category-tag-color-1{
    background-color: var(--secondarycolorpalette-lightpurple-lighther);
  }
  .category-tag-color-2{
    background-color: var( --primary-lighter);
  }
  .category-tag-color-3{
    background-color: var(--secondarycolorpalette-lightorange-lighter);
  }
  .category-tag-color-4{
    background-color: var(--secondarycolorpalette-lightblue-ligtheri);
  }