.bg-box {
  background-color: rgba(185, 222, 44, 1);
  border-radius: 4px;
  padding: 2px 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.tipo {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(49, 36, 120, 1);
  text-align: center;
}
.bg-box.bg-residencial {
  background-color: rgba(185, 222, 44, 1);
}
.bg-box.bg-comercial {
  background-color: #D2C7F7;
  color: rgb(168, 168, 168);
}
.bg-box.bg-oficinas {
  background-color: #5784e4;
  color: white;
}
.bg-box.bg-industrial {
  background-color: #FFBA43;
}
.bg-box.bg-infraestructura {
  background-color: #EBE3D7;
}
.bg-box.bg-blue {
  background-color: #1C4FC1;
  color: white;
}