.cardContrasena {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  padding: 46px 46.94px 52.87px 40.59px;
  box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
    0 0 2px 0 rgba(145, 158, 171, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.headingTwo {
  width: 97.86%;
  height: calc(5.59% - 1.08px);
  margin-bottom: 9.05px;
  margin-left: 7.47px;
  padding: 0.54px 0;
  display: flex;
  align-items: center;
}
.heading {
  height: 20px;
  width: 341px;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(28, 79, 193, 1);
  display: flex;
}
.boxContent {
  border-radius: 11px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.label {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(49, 36, 120, 1);
  margin-bottom: 5px;
  text-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
}
.imputTwo {
  border-radius: 8px;
  padding: 8px 7px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 32px;
  width: 311px;
  border: 1px solid rgba(101, 91, 154, 1);
}
.imput {
  height: 44px;
  width: 308px;
  position: relative;
}
.imputContent {
  padding: 0 16px 0 0;
  position: absolute;
  left: 0;
  top: 0;
}
.iconLef {
  padding: 14px 8.4px 14px 8.41px;
}
.iconLock {
  width: 12.19px;
  height: 16px;
}
.labelThree {
  padding: 10px 84px 10px 0;
}
.labelTwo {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(152, 145, 187, 1);
}
.iconRight {
  padding: 14px 4.76px 14px 4.77px;
}
.iconEye {
  width: 23.47px;
  height: 16px;
}
.imputContentTwo {
  display: flex;
  align-items: flex-start;
  height: 44px;
  align-self: stretch;
}
.iconLefTwo {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9px;
  align-self: stretch;
}
.labelFour {
  display: flex;
  align-items: center;
  width: 235px;
  align-self: stretch;
}
.iconRightTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33px;
  align-self: stretch;
}
.boxContentTwo {
  margin-bottom: 14px;
  border-radius: 11px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}