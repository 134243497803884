.CardWallet p {
  margin-block-start: 7px;
  margin-block-end: 7px;
}
.cardWallet {
  background-color: rgba(28, 79, 193, 1);
  border-radius: 16px;
  padding: 15px 74.5px;
  box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
    0 0 2px 0 rgba(145, 158, 171, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titleTwo {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.title {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(220, 239, 149, 1);
}
.saldocopTwo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.saldocop {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: rgba(203, 230, 97, 1);
}
.saldocopEmphasis1 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: rgba(203, 230, 97, 1);
}
.saldocopEmphasis2 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(203, 230, 97, 1);
}
.saldoDeviseCoin {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(255, 220, 161, 1);
}
.saldoDeviseCoinEmphasis0 {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: rgba(255, 220, 161, 1);
}
.saldoDeviseCoinEmphasis1 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(255, 220, 161, 1);
}
.description {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.text {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(245, 241, 235, 1);
}
.buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonDepositar {
  background-color: rgba(203, 230, 97, 1);
  margin-right: 18px;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 0 8px 16px 0 rgba(145, 158, 171, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 35px;
}
.vector {
  width: 16px;
  height: 16px;
}
.textTwo {
  font-family: "Futura-Bold";
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(28, 79, 193, 1);
}
.buttonRetirar {
  border-radius: 8px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 35px;
  border: 2px solid rgba(203, 230, 97, 1);
}
.textThree {
  font-family: "Futura-Bold";
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(203, 230, 97, 1);
}
