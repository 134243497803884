.etiqueta {
    background-color: rgba(49, 36, 120, 1);
    border-radius: 4px;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .box {
    display: flex;
    align-items: center;
  }
  .vector {
    width: 8.57px;
    height: 12px;
    margin-right: 5px;
  }
  .text {
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    color: rgba(185, 222, 44, 1);
    margin-bottom: 0px;
    padding: 2px;
  }