.itemMenu_active {
  background-color: rgba(28, 79, 193, 1);
  border-radius: 20px;
  padding: 4px 20px 4px 28px;
  display: flex;
  align-items: center;
  width: 250px;/* Con bootstrap */
  /* width: 200px; */
}
.itemMenu {
  border-radius: 20px;
  padding: 4px 20px 4px 28px;
  display: flex;
  align-items: center;
  width: 250px;/* Con bootstrap */
  /* width: 200px; */
}
.boxIcon {
  margin-right: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.iconsMenu {
  height: 25px;
  width: 25px;
  position: relative;
}
.dashboard {
  position: absolute;
  right: -0.86px;
  bottom: -1.75px;
}
.boxName {
  display: flex;
  align-items: center;
  flex: 1;
  align-self: stretch;
}
.dashboardTwo {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #1c4fc1;
}
.dashboardTwo_active {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(185, 222, 44, 1);
}
