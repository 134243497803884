.CardWallet-container .button.btn {
  margin-left: 5px;
  margin-right: 5px;
  width: 135px;
}
.CardWallet-container .button.btn span.label{
  font-weight: bold;
}
.CardWallet-container .button.btn.primary {
  background: var( --primary-main);
  border: 2px solid var(--primary-main);
  color: rgba(28, 79, 193, 1);
}
.CardWallet-container .button.btn.secondary {
  background: rgba(28, 79, 193, 1);
  border: 2px solid var(--primary-main);
  color: var(--primary-main);

}
