.coverCategoria {
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
      0 0 2px 0 rgba(145, 158, 171, 0.2);
    position: relative;
  }
  .bg {
    position: relative;
  }
  .img {
    width: 100%;
    height: 99.38%;
    /* position: absolute; */
    left: 0;
    top: 0;
    border-radius: 16px;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(8, 23, 48, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
    position: relative;
  }
  .text {
    height: 7.98%;
    width: calc(100% - 48px);
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
/*     color: rgba(191, 243, 255, 1); */
    color: var(--primary-lighter);
    display: flex;
    position: absolute;
    left: 24px;
    bottom: 8px;
  }